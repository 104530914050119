<template>
  <div>
    <div id="app" class="app-body">
      <div class="p-3">
        <div class="columns">
          <div class="column">
            <div class="spacer-small"></div>
            <div
              @click="
                () => {
                  $router.go(-1);
                }
              "
              class="is-dark-gray has-pointer"
            >
              <b-icon
                icon="arrow-left-thin-circle-outline"
                size="is-medium"
                class="mb-4"
              />
            </div>
            <p class="is-subtitle is-secondary is-bold">Request Revisions</p>
            <div class="spacer-small"></div>
            <div v-if="loading">
              <Loading />
            </div>
            <div v-else>
              <div class="box-outline p-10">
                <b-input
                  v-model="text"
                  required
                  type="textarea"
                  placeholder="Enter brief here"
                ></b-input>

                <b-field class="file is-info mt-3">
                  <b-upload v-model="file" class="file-label">
                    <span class="file-cta">
                      <b-icon
                        class="file-icon"
                        icon="attachment"
                        size="is-small"
                      ></b-icon>
                      <span class="file-label">Attach File</span>
                    </span>
                    <span class="file-name" v-if="file">
                      {{ file.name }}
                    </span>
                  </b-upload>
                </b-field>
                <hr />
                <div class="columns is-vcentered">
                  <div class="column is-narrow">
                    <b-button
                      type="is-info"
                      :disabled="isSubmitting"
                      @click="submitRevision"
                    >
                      {{ isSubmitting ? "Submitting" : "Submit" }}</b-button
                    >
                  </div>
                  <div v-if="isUploading" class="column is-narrow">
                    <i class="mdi mdi-information-outline mr-1"></i>
                    Uplading file may take some time, please wait.
                  </div>
                </div>
              </div>
              <div class="spacer-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";

export default {
  name: "OrderDetail",
  components: { Loading },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      isUploading: false,
      text: undefined,
      file: null,
      data: [
        {
          fileName: "Brand name document v1.0",
          date: "November 20, 2021",
          fileType: ".pdf",
        },
        {
          fileName: "Brand name document v2.0",
          date: "November 21, 2021",
          fileType: ".pdf",
        },
      ],
    };
  },
  computed: {
    orderId() {
      return this.$route.query.id;
    },
    orderDetail() {
      return this.$store.state.orderDetail;
    },
  },
  methods: {
    async submitRevision() {
      this.isSubmitting = true;
      const metadata = JSON.stringify({ orderId: this.orderId });
      const request = new FormData();
      if (this.file) {
        request.append("file", this.file);
        this.isUploading = true;
      }
      request.append("text", this.text);
      request.append("metadata", metadata);
      await this.$store.dispatch("requestRevision", request);
      this.isSubmitting = false;
      this.isUploading = false;
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("getOrderDetail", this.orderId);
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
